exports.components = {
  "component---src-components-layout-with-mdx-js-content-file-path-src-docs-finish-mdx": () => import("./../../../src/components/LayoutWithMdx.js?__contentFilePath=/opt/build/repo/src/docs/finish.mdx" /* webpackChunkName: "component---src-components-layout-with-mdx-js-content-file-path-src-docs-finish-mdx" */),
  "component---src-components-layout-with-mdx-js-content-file-path-src-docs-index-mdx": () => import("./../../../src/components/LayoutWithMdx.js?__contentFilePath=/opt/build/repo/src/docs/index.mdx" /* webpackChunkName: "component---src-components-layout-with-mdx-js-content-file-path-src-docs-index-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-brew-js": () => import("./../../../src/pages/brew.js" /* webpackChunkName: "component---src-pages-brew-js" */),
  "component---src-pages-find-belgium-js": () => import("./../../../src/pages/find/belgium.js" /* webpackChunkName: "component---src-pages-find-belgium-js" */),
  "component---src-pages-find-france-js": () => import("./../../../src/pages/find/france.js" /* webpackChunkName: "component---src-pages-find-france-js" */),
  "component---src-pages-find-index-js": () => import("./../../../src/pages/find/index.js" /* webpackChunkName: "component---src-pages-find-index-js" */),
  "component---src-pages-find-poland-js": () => import("./../../../src/pages/find/poland.js" /* webpackChunkName: "component---src-pages-find-poland-js" */),
  "component---src-pages-find-spain-js": () => import("./../../../src/pages/find/spain.js" /* webpackChunkName: "component---src-pages-find-spain-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-index-js": () => import("./../../../src/pages/learn/index.js" /* webpackChunkName: "component---src-pages-learn-index-js" */),
  "component---src-pages-tools-caffeine-calc-js": () => import("./../../../src/pages/tools/caffeine_calc.js" /* webpackChunkName: "component---src-pages-tools-caffeine-calc-js" */),
  "component---src-pages-tools-index-js": () => import("./../../../src/pages/tools/index.js" /* webpackChunkName: "component---src-pages-tools-index-js" */),
  "component---src-pages-tools-savings-calc-js": () => import("./../../../src/pages/tools/savings_calc.js" /* webpackChunkName: "component---src-pages-tools-savings-calc-js" */),
  "component---src-pages-tools-timer-js": () => import("./../../../src/pages/tools/timer.js" /* webpackChunkName: "component---src-pages-tools-timer-js" */)
}

