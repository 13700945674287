import React from 'react';
import './src/styles/global.css';
import { CountryProvider } from './src/contexts/CountryContext';

export const onInitialClientRender = () => {
   
    document.documentElement.classList.add('h-full', 'bg-gray-50');
   document.body.classList.add('h-full');
  
  const head = document.head;
  const manifestLink = document.createElement('link');
  manifestLink.rel = 'manifest';
  manifestLink.href = '/site.webmanifest';
  head.appendChild(manifestLink);

  const msTileColorMeta = document.createElement('meta');
  msTileColorMeta.name = 'msapplication-TileColor';
  msTileColorMeta.content = '#da532c';
  head.appendChild(msTileColorMeta);

  const themeColorMeta = document.createElement('meta');
  themeColorMeta.name = 'theme-color';
  themeColorMeta.content = '#ffffff';
  head.appendChild(themeColorMeta);

};


export const wrapRootElement = ({ element }) => {
  return <CountryProvider>{element}</CountryProvider>;
};